%main-color {
    color: #fff;
}

%blue {
    color: #205a88;
}

%smoothing {
    -webkit-font-smoothing: antialiased;
}

%gold {
    color: #FFCD00;
}


%main-color {
    color: #fff;
}

%blue {
    color: #205a88;
}

%smoothing {
    -webkit-font-smoothing: antialiased;
}

$iowa-gold: #FFCD00;
