
.paragraphs-item-clas-maui-paragraph .field {
    font-size: .9em;
    line-height: 1.3;
}

.uiowa-maui-course-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    background-color: #f1f1f1;
}

.field-name-field-clas-maui-course,
.field-name-field-clas-maui-course-2,
.field-name-field-clas-maui-course-3 {
    display: inline-block;
    width: 80%;
}

.field-name-field-clas-maui-semester-hours {
    display: inline-block;
    width: 20%;
    background-color: #f1f1f1;
    padding: 10px;
    text-align: center;
}

.uiowa-maui-course-info a {
    color: #FFCD00;
    background-color: #000;
    padding: 10px 40px;
    font-weight: bold;
}

.uiowa-maui-course-description {display: none;}

.uiowa-maui-course-info div {
    padding: 10px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}
