.callout-block {
  position: relative;

  @include span(12);

  @include breakpoint($small) {
    //@include span(3);
    margin-right: 10px;
  }

  @include breakpoint($medium) {
    // @include span(3);
    margin-right: 10px;
  }

  @include breakpoint($large) {
    // @include span(3);
    margin-right: 10px;
  }

  @include breakpoint($medium) {
    .no-flexbox & {
      @include span(3);
    }
  }

  @include breakpoint($all) {
    .no-flexbox & {
      @include span(3);
    }
  }

  &:nth-child(5),
  &.callout-block--last {
    margin-right: 0;
  }

  li {
    clear: both;
  }

  &.callout-block--tertiary {
    @include breakpoint($small) {
      @include span(4);
    }

    @include breakpoint($medium) {
      @include span(4);
    }

    @include breakpoint($large) {
      @include span(4);
    }

    &:nth-child(3),
    &.callout-block--last {
      margin-right: 0;
    }
  }
}

.feature-block {
  position: relative;

  @include span(12);

  @include breakpoint($small) {
    @include span(3);
  }

  @include breakpoint($medium) {
    @include span(3);
  }

  @include breakpoint($large) {
    @include span(3);
  }

  //  &:nth-child(5),
  &.feature-block--last {
    margin-right: 0;
  }

  li {
    clear: both;
  }

  &.feature-block--tertiary {
    @include breakpoint($small) {
      @include span(4);
    }

    @include breakpoint($medium) {
      @include span(4);
    }

    @include breakpoint($large) {
      @include span(4);
    }

    &:nth-child(3),
    &.feature-block--last {
      margin-right: 0;
    }
  }
}

//.feature-block--callout .container,
.not-front .feature-block--callout .content {
  @include breakpoint($all) {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
  }
}

.feature-block--callout {
  padding: 20px 0;

  .front & {
    background: #1e1d1d;
  }

  a {
    color: #ffcc33;
  }

  p {
    color: #fff;
    line-height: 1.2;

    a {
      color: #fff;
    }
  }

  strong {
    display: block;
    padding-bottom: 3px;
    //font-size: .9em;
    color: #ffcc33;
    @extend %font-bold;
  }

  .feature-block {
    padding: 15px;
    border-radius: 3px;
    background: #373636;
    .not-front & {
      margin-top: -20px;
      margin-bottom: 35px;
    }
  }

  .callout-block {
    padding: 15px;
    border-radius: 3px;
    background: #373636;

    .not-front & {
      margin-top: -20px;
      margin-bottom: 35px;
    }
  }

  span {
    position: absolute;
    padding: 10px;
    border-radius: 50%;
    background: #ffcc33;
    height: 40px;
    text-align: center;
    width: 40px;
  }

  i {
    color: #000;
  }

  p {
    padding-left: 50px;
  }
}


.feature-block--callout .feature-block.feature-block-blk {
  background: none;
}

.feature-block--content {
  display: inline-block;
  margin: 10px;
  padding: 10px;
  background: #fff;

  h2 {
    color: #000;
    font-size: 1.2em;
    @extend %font-bold;
  }

  h3 {
    color: #7c6221;
    font-size: 1.2em;
  }

  p {
    margin: 20px 0 0 0;
    color: #5e5e5e;
    //font-size: .9em;
    line-height: 1.3;

    em {
      font-style: italic;
    }
  }

  a {
    word-wrap: break-word;
    // This is the dangerous one in WebKit, as it breaks things wherever
    word-break: break-all;
    // Instead use this non-standard one:
    word-break: break-word;
    -ms-word-break: break-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    color: #369;
    overflow-wrap: break-word;
    // Adds a hyphen where the word breaks, if supported (No Blink)
    -ms-hyphens: auto;
  }
}

.feature-block--box {
  min-height: 420px;
  margin-top: 25px;
  border: 1px solid #ccc;

  img {
    width: 100%;
  }
}

.feature-apply {
  min-height: 400px;
  padding: 40px 0;
  background: url("/sites/all/themes/clas_department/images/apply.jpg") no-repeat;
  background-size: cover;

  h2 {
    padding: 20px 0;
    text-align: left;
    color: #fff;
    text-shadow: 0 1px 1px #000;
    font-size: 3em;
    @extend %font-bold;
  }

  p {
    display: none;
    width: 100%;
    margin: 20px 0;
    color: #fff;
    text-shadow: 0 1px 1px #000;
    font-size: 1.3em;

    @extend %font-main;

    @include breakpoint($small) {
      display: block;
    }

    @include breakpoint($medium) {
      display: block;
      width: 60%;
    }

    @include breakpoint($large) {
      display: block;
      width: 60%;
    }
  }
}

.feature-btn {
  display: inline-block;
  width: 60%;
  margin-top: 10px;
  padding: 15px;
  text-align: center;
  letter-spacing: .05em;
  text-transform: uppercase;
  color: #333;
  border-radius: 3px;
  background: #fff;
  font-size: 1.2em;

  @extend %font-bold;

  @include breakpoint($small) {
    width: 40%;
    margin-top: 40px;
  }

  @include breakpoint($medium) {
    width: 20%;
    margin-top: 40px;
  }

  @include breakpoint($large) {
    width: 20%;
    margin-top: 40px;
  }
}



.areas__container,
.areas--container {
  padding-bottom: 30px;

  @include breakpoint($all) {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    color: #fff;
  }
}

.areas__container a,
.areas--container a {
  display: flex;
  display: flex;
  flex: 1;
  flex-flow: column;
  text-decoration: none;

  .front & {
    display: flex;
    display: flex;
    flex: 1;
    flex-flow: column;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}


.areas__container li li a {
  display: inline-block;
  cursor: pointer;
}

.areas__wrapper {
  background: #ededed;

  @include breakpoint($all) {
    padding: 0 0 0 15px;
  }
}

.areas__heading {
  width: 100%;
  margin: 0 0 20px;
  text-align: center;

  span {
    z-index: 3;
    display: inline-block;
    padding: 15px 20px;
    text-transform: uppercase;
    color: #000;
    border-radius: 3px;
    background: #ffcd00;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    font-size: 1.2em;
    font-weight: 400;

    @extend %font-bold;

    @include breakpoint($all) {
      position: relative;

      margin-top: -30px;
    }
  }
}


.areas {
  position: relative;
  width: 100%;
  margin: 0 15px 20px 0;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);

  @include breakpoint($all) {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 0 180px;
    -ms-flex: 1 0 180px;
    flex: 1 0 180px;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
  }

  .areas__body,
  p {
    padding: 20px;
  }

  .areas__img {
    align-self: flex-start;
    width: 100%;

    img {
      align-self: flex-start;
      width: 100%;
    }
  }

  .areas__content {
    @include breakpoint($all) {
      display: flex;
      flex-flow: column;
    }
  }
}




@media all and (-ms-high-contrast:none) {
  .areas {
    display: block;
  }

  /* IE10 */
  *::-ms-backdrop,
  .areas {
    display: block;
  }

  /* IE11 */
}


.areas__angle,
.video__angle {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-width: 70px 70px 0 0;
  border-style: solid;
  border-color: #ffcd00 transparent transparent transparent;
}

.areas i,
.areas .icon {
  position: absolute;
  top: 2px;
  left: 0px;
  width: 40px;
  color: #000;
}

.areas__link {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin-top: auto;
  padding: 10px;
  text-transform: uppercase;
  color: #000;
  background: #dad7d7;
  font-size: .8em;
  @extend %font-bold;

  span {
    margin: 0 auto;
  }

  a {
    display: block;
    text-align: left;
    color: #000;

    &:after {
      position: absolute;
      padding: 0 3px 0 5px;
      content: "\f0da";
      color: #fff;
      border-radius: 50%;
      @extend %icon;
    }
  }
}

.areas__sub {
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  font-size: 1.2em;
  line-height: 1.3;
  @extend %font-bold;
}

.front .wrapper img.main {
  width: 100%;
}
