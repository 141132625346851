.flexbox-container .slides li {
  @include breakpoint($medium) {
    display: -ms-flex !important;
    display: -webkit-flex !important;
    display: flex !important;
  }

  @include breakpoint($large) {
    display: -ms-flex !important;
    display: -webkit-flex !important;
    display: flex !important;
  }
}

.flexbox-container .slides li .views-field-field-slideshow-image {
  width: 100%;

  @include breakpoint($medium) {
    width: 60%;
  }

  @include breakpoint($large) {
    width: 60%;
  }

  img {
    width: 100%;
  }
}

.flexbox-container .slides li .views-field-field-slideshow-link {
  width: 100%;

  @include breakpoint($medium) {
    width: 40%;
  }

  @include breakpoint($large) {
    width: 40%;
  }
}

.flexbox-container .slides li .views-field-field-slideshow-link {}

.view-home-slideshow {
  .caption__flex {
    padding: 60px 40px 40px;
    color: #fff;
  }
  .caption__content {
    line-height: 1.4;
    a {
      color: $iowa-gold;
    }
  }
}


@media all and (-ms-high-contrast:none) {
  .front .view-home-callouts .feature-block {
    display: block;
    -webkit-flex: 1 0 280px;
    -ms-flex: 1 0 280px;
    flex: 1 0 280px;
  }

  /* IE10 */
  *::-ms-backdrop,
  .front .view-home-callouts .feature-block {
    display: block;
    -webkit-flex: 1 0 340px;
    -ms-flex: 1 0 340px;
    flex: 1 0 340px;
  }

  /* IE11 */
}


//  Open Region Area on Home
#block-views-home-callouts-block {
  @include breakpoint($all) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.paragraphs-items-field-callout-block,
.paragraphs-items-field-additional-callout-block {
  margin-bottom: 20px;
}

.paragraphs-items-field-callout-block,
.paragraphs-items-field-additional-callout-block,
.view-home-callouts .view-content,
.view-home-callouts .view-content ul {
  @include breakpoint($all) {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    width: 100%;
  }
}

.node-type-home-page .feature-block {
  float: none;
  width: 100%;
  margin-right: 0;
}

.feature-block--content {
  ul {
    display: list-item !important;
    margin: 20px 0 0 20px;
    list-style: square;
    color: #5e5e5e !important;
    li {
      padding-bottom: 5px;
      color: #5e5e5e;
      line-height: 1.3;
      p {
        margin: 0;

        em {
          font-style: italic;
        }
      }

    }
  }
}

.field-name-field-callout-headline {
  color: #000;
  font-size: 1.2em;
  @extend %font-bold;
}

.node-type-home-page .block-title {
  width: 100%;
  margin-bottom: 20px;
  @extend %font-bold;
  font-size: 1.3em;
}

.view-home-callouts .feature-block,
.paragraphs-items-field-callout-block .field-name-field-callout-block,
.paragraphs-items-field-additional-callout-block .field-name-field-additional-callout-block {
  position: relative;
  width: 100%;
  min-height: 65px;
  margin: 0 1%;
  @include breakpoint($all) {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  &.feature-block--thin {
    @include breakpoint($all) {
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
  &.views-row-last {
    // margin-right: 0;
  }
  &:first-child {
     @include breakpoint($all) {
    margin-left: 0;
     }
  }
  &:last-child {
     @include breakpoint($all) {
    margin-right: 0;
     }
  }
}

 /*
  .view-home-callouts ul li.feature-block:first-child {
    margin-left: 0;
  }

  .view-home-callouts ul li.feature-block:last-child {
    margin-right: 0;
  }
*/

.paragraphs-items-field-callout-block .field-name-field-callout-block,
.paragraphs-items-field-additional-callout-block .field-name-field-additional-callout-block {
  background: #f1f1f1;

  .field-name-field-callout-link {
    min-height: 60px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: .9em;
    line-height: 1.2;
    @extend %font-bold;
    a {
      color: #000;
    }
  }
}


.node-type-home-page .callout-section .feature-block {
  background-color: #fff;
}

.view-home-callouts .feature-block a {
  display: flex;
  display: flex;
  flex: 1;
  flex-flow: column;
  text-decoration: none;
  img {
    flex-shrink: 0;
    width: 100%;
  }
}

.fullscreen-container {
  padding-right: 20px;
  padding-left: 20px;
}

.node-type-home-page .feature-block--callout {
  background: none;
}

.view-home-slideshow .caption__text {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 20px 0;
  color: #fff;
  line-height: normal;

  @include breakpoint($all) {
    position: absolute;
    z-index: 200;
    top: 5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @include breakpoint($small) {
    width: auto;
  }
  @include breakpoint($medium) {
    width: 40%;
  }
  @include breakpoint($large) {
    width: 30%;
  }
  @include breakpoint($xlarge) {
    width: 24%;
  }
  @include breakpoint($xmlarge) {
    width: 21%;
  }
  @include breakpoint($xxlarge) {
    width: 17%;
  }
  @include breakpoint($xxxlarge) {
    width: 15%;
  }
  @include breakpoint($xxxxlarge) {
    width: 13%;
  }
  @include breakpoint($xxxxxlarge) {
    width: 11%;
  }
}

.view-home-slideshow .caption__heading {
  margin-bottom: 20px;
  font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif;
  font-size: 1.8em;
  line-height: 1.2;
}

.view-home-slideshow .slider__content {
  @include breakpoint($all) {
    width: 70%;
    margin: 0 auto!important;
  }
}

.view.view-home-slideshow .caption__content {
  margin-bottom: 20px;
}

.caption-slideshow .slides .views-field.views-field-field-slideshow-image::before {
  @include breakpoint($all) {
    position: absolute;
    width: 40%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.7);
  }

  @include breakpoint($small) {
    width: 100%;
  }

  @include breakpoint($medium) {
    width: 50%;
  }

  @include breakpoint($large) {
    width: 40%;
  }
}
