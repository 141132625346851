.view-dwllc-program-finder {
  img {
    //width: 100%;
  }
  .program-heading {
    font-size: 1.3em;
    margin: 20px;
    color: #000;
  }
  .program-content {
    margin: 0 20px 20px;
    color: #000;
  }
  .views-field-field-dwllc-program-link {
    display: flex;
    align-items: center;
    margin-top: auto;
    color: #000;
    font-size: .8em;
    text-transform: uppercase;
    .btn {
      margin: 20px;
    }
  }
}

.page-program-finder .main-container {
  padding-top: 20px;
  max-width: 74em;
  padding-bottom: 40px;
}

.page-program-finder .horizontal-list .view-content,
.page-program-finder.horizontal-list .view-content {
  @include breakpoint($all) {
    max-width: 74em;
  }
}

.page-program-finder .horizontal-list li,
.page-program-finder.horizontal-list li {
  background: #fff;
  min-height: 150px;
  padding: 0 !important;
  @include breakpoint($all) {
    -webkit-flex: 1 0 32%;
    -ms-flex: 1 0 32%;
    flex: 1 0 32%;
    flex-grow: 0;
    margin: 0 1% 2% !important;
    &:first-child, &:nth-child(3n+1) {
      margin-left: 0!important;
    }
    &:last-child, &:nth-child(3n+3) {
      margin-right: 0 !important;
    }
  }
  /*
  @include breakpoint($large) {
    -webkit-flex: 1 0 32%;
    -ms-flex: 1 0 32%;
    flex: 1 0 32%;
    flex-grow: 0;
    margin: 0 1% 2% !important;
    &:first-child,
    &:nth-child(3n+1) {
      margin-left: 0 !important;
    }
    &:last-child,
    &:nth-child(3n+3) {
      margin-right: 0 !important;
    }
  }*/
}

.page-program-finder.horizontal-list li {
  border: 1px solid #ccc;
}

.page-program-finder.horizontal-list li a:hover {
  text-decoration: none;
}
.page-program-finder .horizontal-list ul li:last-child,
.page-program-finder.horizontal-list ul li:last-child {
  @include breakpoint($all) {
    flex-grow: 0;
  }
}

.page-program-finder .horizontal-list ul {
  margin: 20px 0 0;
}

.page-program-finder .horizontal-list li {
  @include breakpoint($all) {
    //max-width: 33.3%;
  }
}

.main .program-finder--page input[type=submit] {
  padding: 11px 15px;
}

.program-finder {
  background: #000;
  padding: 35px 0;
  &.program-finder-top {
    padding: 35px 0 10px 0;
  }
  &.program-finder-bottom {
    padding: 0 0 35px 0;
  }
  &.program-finder--page {
    padding: 20px;
    margin: 0;
    background: #fff;
    border: 1px solid #ccc;
    h2 {
      color: #000;
    }
  }
  .container {
    padding: 0;
  }
  h2 {
    font-size: 30px;
    font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
    .front & {
      color: #fff;
    }
  }
  p {
    margin-bottom: 10px;
    .front & {
      color: #fff;
    }
  }
  .content {
    max-width: 68em;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    padding: 0 2%;
    @include breakpoint($large) {
      max-width: 73em;
      padding: 0;
    }
  }

}


.program-finder .views-exposed-form .views-exposed-widget {
  @include breakpoint($medium) {
    width: 46.5%;
  }
  @include breakpoint($large) {
    width: 46.5%;
  }
}

.program-finder .views-exposed-form .views-exposed-widget.views-submit-button {
  width: 4%;
}

.program-finder .views-exposed-form .form-type-select:after {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #000;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  background: #FFCD00;
  z-index: 1;
  text-align: center;
  width: 15%;
  height: 100%;
  pointer-events: none;
  box-sizing: border-box;
}

.program-finder .views-exposed-form .form-type-select {
  position: relative;
  display: block;
  background: #FFCD00;
}

.program-finder .form-type-select select {
  background: #FFCD00;
  border: none;
  width: 100%;
}

.program-finder .form-submit {
  background-color: #666;
  color: #ffffff;
  font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif;
  padding: 1em 2em;
  display: inline-block;
  border: none;
  border-radius: 0;
  line-height: 1;
  font-size: 1.1em;
  padding: .6em .8em .5em .8em;
}


.view-dwllc-program-finder.horizontal-list .view-content,
.view-dwllc-program-finder.horizontal-list .view-header {
  padding: 0;
}


.program-finder .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 0;
}


.page-program-finder,
.page-degree-finder {
  form h2 {
    display: none;
  }
}


.program-finder .views-exposed-form label {
    @extend .element-invisible;
}


