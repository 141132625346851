.site-slogan {
  display: none;
}

.main fieldset {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  legend {
    font-size: 1.5em;
  }
}

form label {
  margin-bottom: 10px;
}

.main input[type=hidden],
.main input[type=password],
.main input[type=text],
.main input[type=email],
.main input[type=number] {
  display: block;
  width: 100%;
  padding: 0.5em 0.75em;
  font-size: 1em;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.main select {
  padding: 4px;
  border: 1px solid #0b263b;
  border-radius: 2px;
}

.webform-component-select select:after {
  content: " sdf";
  position: absolute;
  top: 50%;
  right: 1em;
  z-index: 2;
  pointer-events: none;
  display: none;
}

.form-managed-file .form-file {
  margin: 0;
  padding: 10px;
  background: #f1f1f1;
  width: 100%;
}

/* Container used for styling the custom select, the buttom class below adds the
 * bg gradient, corners, etc. */
.webform-component-select {
  position: relative;
  display: block;
}

/* This is the native select, we're making everything but the text invisible so
 * we can see the button styles in the wrapper */
.webform-component-select select,
.form-type-select select {
  width: 100%;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  padding: .6em 2em .5em .8em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
}

.form-type-select {
  position: relative;
}

.form-type-select:after {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #000;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  text-align: center;
  width: 50px;
  height: 100%;
  pointer-events: none;
  box-sizing: border-box;
}

.form-type-select select {
  //width: auto;
  display: inline;
}

.webform-container-inline label {
  display: block;
}

.main textarea {
  resize: vertical;
  border: 1px solid #0b263b;
  border-radius: 2px;
}

.main button,
.main .button,
.main input[type=submit],
.main input[type=reset] {
  padding: 8px 20px;
  cursor: pointer;
  text-transform: uppercase;
  color: #ffcd00;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  background-color: #000;
  text-shadow: 0 1px rgba(0, 0, 0, .4);
  @extend %font16;
  font-weight: bold;
  //display: block;
  margin: 15px 0;
  a {
    text-decoration: none;
    color: #000;
  }
  &:hover,
  &:active {
    background: #333;
    color: #fff;
    text-shadow: none;
  }
}

.main a.button {
  color: #000;
}

.main a.btn,
.btn {
  font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif;
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 10px;
  text-align: center;
  color: #000;
  border-radius: 3px;
  background: #edc649;
  &.btn--reversed {
    color: #fff;
    background: #000;
  }
}

.btn--full {
  width: 100%;
  margin: 0 auto;
}

#skip-link a:focus,
#skip-link a:active {
  display: block;
  padding: 10px;
  text-align: center;
  background: #f1f1f1;
}
input:focus,
select:focus,
textarea:focus {
  border: dotted 2px #333;
}


/********************** File Icons **********************/

.main a[href$='.pdf'] {
  padding-right: 20px;
  background: url(/modules/file/icons/application-pdf.png) no-repeat right;
}

.main a.btn[href$='.pdf'] {
  padding-right: 20px;
  background: #edc649;
}

.main .left a[href$='.pdf'],
.main .right a[href$='.pdf'] {
  padding-right: 0;
  background: none;
}

.main a[href$='.doc'],
.main a[href$='.docx'] {
  padding-right: 20px;
  background: url(/modules/file/icons/x-office-document.png) no-repeat right;
}

.main a[href$='.xls'],
.main a[href$='.xlsx'] {
  padding-right: 20px;
  background: url(/modules/file/icons/x-office-spreadsheet.png) no-repeat right;
}

.main a[href$='.ppt'],
.main a[href$='.pptx'] {
  padding-right: 20px;
  background: url(/modules/file/icons/x-office-presentation.png) no-repeat right;
}

.file-icon {
  display: none;
}

.file-icon-bg {
  background: none !important;
}

.main a.external-link[href$=".pdf"] {
  padding-right: 0px;
  background: none;
}

// add to main theme
#main .file a[href$='.pdf'],
#main .file a[href$='.doc'],
#main .file a[href$='.docx'],
#main .file a[href$='.dot'],
#main .file a[href$='.dotx'],
#main .file a[href$='.ppt'],
#main .file a[href$='.pptx'],
#main .file a[href$='.xls'],
#main .file a[href$='.xlsx'] {
  background: none;
}

/********************** Drupal **********************/

.cke_panel_frame {
  background: #fff !important;
}

.front #page-title {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
}

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
}

.tabs>ul.primary {
  height: 30px;
  margin: 30px 0 25px;
  padding: 0;
  color: #eee;
  border-color: rgba(102, 51, 0, .2);
  border-bottom: none;
  -webkit-box-shadow: 0 1px rgba(255, 255, 255, .05);
  box-shadow: 0 1px rgba(255, 255, 255, .05);
  text-shadow: 0 1px rgba(0, 0, 0, .8);
  @extend %font17;
}

.tabs>ul.primary li a {
  margin: 0;
  padding: 7px 14px;
  color: #333;
  border-color: transparent;
  border-radius: 0;
  text-shadow: none;
  border: none;
  background: rgba(255, 206, 0, 0.3);
  box-shadow: none;
  &:hover {
    background: #ccc;
  }
}

.tabs>ul.primary li.active a {
  color: #fff !important;
  border-style: solid solid none;
  border-bottom-color: transparent;
  background: #333;
  text-shadow: none;
  border: none;
}

.tabs ul.primary li {
  display: inline;
}
