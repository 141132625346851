/********************** Search ********************/

.block-search-form .content {
   // display: none;
    width: 80%;
    margin-top: 10px;
    @include span(12);
    @include breakpoint($small) {
        display: block;
        float: right;
        width: auto;
        margin: 0 0 0 20px;
    }
    @include breakpoint($medium) {
        display: block;
        float: right;
        width: auto;
        margin: 0 0 0 20px;
    }
    @include breakpoint($large) {
        display: block;
        float: right;
        width: auto;
        margin: 0 0 0 20px;
    }
}

#block-search-form {
  width: 70%;
  margin: 0 auto;
  @include breakpoint($all) {width: auto;margin: 0;}
}

.block-search-form.expanded {
    display: block;
    height: 100px;
    background: red;
}

//form
#search-block-form {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 4px 0;
    color: #b9b9b9;
    //border: 1px solid #101010;
    border-radius: 0;
    background-color: #4b4b4b;
    //background-image: none;
    //background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #4b4b4b), color-stop(100%, #3b3b3b));
    //background-image: -webkit-linear-gradient(top, #4b4b4b 0%, #3b3b3b 100%);
    //background-image: -moz-linear-gradient(top, #4b4b4b 0%, #3b3b3b 100%);
    //background-image: -o-linear-gradient(top, #4b4b4b 0%, #3b3b3b 100%);
    //background-image: linear-gradient(to bottom, #4b4b4b 0%, #3b3b3b 100%);
    //-webkit-box-shadow: inset 0 1px 1px 0 #000, 0 2px 2px 0 #000;
    //box-shadow: inset 0 1px 1px 0 #000, 0 2px 2px 0 #000;
    text-shadow: none;
    //filter: progid: DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FF4B4B4B', endColorstr='#FF3B3B3B');
    //filter: dropshadow(enabled=false);
    @include breakpoint($small) {
        width: 100%;
        margin: 9px 0;
    }
    @include breakpoint($medium) {
        min-width: 100px;
        margin: 9px 0;
    }
    @include breakpoint($large) {
        min-width: 100px;
        margin: 9px 0;
    }
}

// input
#edit-search-block-form--2 {
    font-size: 16px;
    float: left;
    width: 100%;
    height: 20px;
    margin-right: 5px;
    padding: 3px 5px 0 10px;
    color: #999;
    border: medium none;
    background: none repeat scroll 0 0 transparent;
    @include breakpoint($small) {
        font-size: .8em;
        width: auto;
    }
    @include breakpoint($medium) {
        font-size: .8em;
        width: auto;
    }
    @include breakpoint($large) {
        font-size: .8em;
        width: auto;
    }
}

.block-search-form #edit-submit--2,
.block-search-form #edit-submit--3,
.block-search-form #edit-submit,
.block-search-form #edit-submit--4 {
    font-size: 10px;
    font-weight: normal;
    position: absolute;
    top: 0;
    right: 0;
    height: 28px;
    margin: 0;
    text-transform: uppercase;
    color: #999;
    border: 1px solid #1c1c1c;
    border-radius: 0;
    background-color: #212121;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.form-type-textfield.form-item-search-block-form {
    line-height: 0;
    margin: 0;
    @include breakpoint($small) {
        padding-right: 64px;
    }
    @include breakpoint($medium) {
        padding-right: 64px;
    }
    @include breakpoint($large) {
        padding-right: 64px;
    }
}

.search-toggle {
    font-size: 1em;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    width: 44px;
    height: 45px;
    padding: 10px;
    padding: 10px;
    cursor: pointer;
    text-align: left;
    color: #ffce00;
    border: none;
    background-color: #1c1915;
    @include breakpoint($small) {
        display: none;
    }
    @include breakpoint($medium) {
        display: none;
    }
    @include breakpoint($large) {
        display: none;
    }
    i {
        float: right;
    }
}

.block-search-form h2 {
    @extend .element-invisible;
}

// GSA

.main table.gsc-table-result,
.main table.gsc-resultsHeader,
.main table.gsc-above-wrapper-area-container {
    margin: 0;
}

.main table.gsc-table-result th, .main table.gsc-table-result td {
    padding: 0;
}

.cse .gsc-control-cse, .gsc-control-cse {
    padding: 0!important;
}


.gsc-selected-option-container {
    max-width: 100%!important;
}

.gsc-resultsHeader {
    margin-bottom: 20px;
}

input.gsc-search-button {
    padding: 0 18px!important;
    height: 38px!important;
}

#uiowa-bar #uiowa-search {
    right: 0;
}

#uiowa-bar #uiowa-search input#edit-submit-search {
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 0;
}
