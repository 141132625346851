/********************** Views  **********************/

.views-label {
  font-weight: normal;
  @extend %smoothing;
  @extend %font17;
}

//.views-field-field-phone, .views-field-field-office { white-space: nowrap; }
.clusters h3 {
  padding: 0 0 10px 0;
  border-bottom: 1px solid #ccc;
}

.view p {
 // margin: 0 0 20px 0;
}

.not-front .main-content .view-header {
  margin: 30px 0;
  border-bottom: 1px solid #ccc;
}

.field-label {
  @extend %font16;
  @extend %smoothing;
}

.field .field-label {
  line-height: 1.5;
}

.node-type-profile .field {
  &.field-name-body {
    margin-top: 50px;
  }
}

.node-type-recordings .field-type-image,
.node-type-profile .field-name-field-personnel-image,
.node-type-books .field-type-image,
.node-type-profile .field-name-field-image,
.node-type-profile .field-name-field-profile-image {
  @include breakpoint($small) {
    float: right;
    margin: 10px 0 15px 15px;
  }
  @include breakpoint($medium) {
    float: right;
    margin: 10px 0 15px 15px;
  }
  @include breakpoint($large) {
    float: right;
    margin: 10px 0 15px 15px;
  }
}

.node-type-photo-feature .field-type-image,
.node-type-feature .field-type-image,
.node-spotlight .field-type-image,
.node-type-article .field-type-image,
.node-type-news .field-name-field-image,
.node-type-student-profile .field-type-image {
  @include breakpoint($small) {
    float: right;
    margin: 10px 0 20px 20px;
  }
  @include breakpoint($medium) {
    float: right;
    margin: 10px 0 20px 20px;
  }
  @include breakpoint($large) {
    float: right;
    margin: 10px 0 20px 20px;
  }
}

.field-name-field-professional-title,
.field-name-field-profile-title,
.field-name-field-title {
  font-style: italic;
  @extend %smoothing;
}

.field-name-field-top-image {
  margin-bottom: 2%;
}


/********************** CLAS BASE **********************/

.content-box-headline {
  @extend %font17;
  @extend %smoothing;
  margin: 20px 0;
  padding-bottom: 5px;
  color: #666;
  font-size: 1.3125em;
  line-height: 1.25714em;
  border-bottom: 1px solid #333;
  .horizontal-list & {
    margin: 20px 0 8px;
  }
  a {
    color: #666;
  }
}

.content-box-gallery {
  img {
    background: #fff;
    padding: 10px;
    max-width: 95%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, .8);
  }
}

.content-box-link {
  position: absolute;
  top: 24px;
  right: 0;
  a {
    color: #6a6a6a;
  }
}

.subheading {
  margin: 0 0 10px 0;
  text-transform: none;
  font-size: 0.875em;
  line-height: 1.54286em;
  color: #6a6a6a;
  @extend %smoothing;
  &--date {
    font-style: italic;
  }
  em {
    font-style: italic;
  }
  .content-box-list & {
    margin: 0;
  }
}

.title {
  line-height: 1em;
  @extend %smoothing;
  a {
    color: #333;
    font-size: 1em;
    line-height: 1.2;
    .not-front & {
      font-size: 1em;
      line-height: 1.2;
      color: #336699;
    }
  }
}

.content-box-column-list {
  .title {
    @extend %font16;
    @extend %smoothing;
    font-size: 0.9375em;
    line-height: 1.6em;
  }
  p {
    @extend %font16;
    @extend %smoothing;
    font-size: 0.75em;
    line-height: 1.8em;
    em {
      font-style: italic;
    }
    strong {
      font-weight: bold;
    }
  }
  > li {
   // width: 31.3%;
   // float: left;
    img {
      margin-bottom: 10px;
    }
   /* &.views-row-2,
    &.views-row-5 {
      margin: 0 3%;
    }*/
    ul {
      list-style: square;
      margin-left: 40px;
      font-size: 0.775em;
      line-height: 1.24286em;
      @extend %font16;
      a {
        color: #000;
      }
    }
  }
}

.content-box-list {
  //margin-bottom: 50px;
  > li {
    margin: 0 0 20px 0;
  }
  .title {
    margin-top: 0em;
    padding-top: 0em;
    padding-bottom: 0em;
    margin-bottom: 0em;
    a {
      color: #333;
      font-size: 1em;
      line-height: 1.35em;
      .page-news & {
        font-size: 0.875em;
        line-height: 1.54286em;
      }
    }
  }
  .subheading {
    text-transform: none;
    font-size: 0.875em;
    line-height: 1.54286em;
    color: #6a6a6a;
    .view & {
      font-size: 0.875em;
      line-height: 1.64286em;
    }
  }
}

.content-box-column-list>li h3 {
  margin-bottom: 10px;
}


/********************** News Events  **********************/

.node-type-news-events,
.node-type-spotlight,
.node-type-news {
  .field-name-body {
    margin-top: 20px;
  }
  .field-name-field-date,
  .field-name-field-location {
    font-style: italic;
    color: #666;
  }
  .field-name-field-image {
    float: right;
    margin: 0 0 20px 20px;
  }
}

.node-type-mastercal-event {
  .field-name-field-mc-image {
    float: right;
    margin: 0 0 20px 20px;
  }
  .date-display-single {
    opacity: .7;
  }
}

.node-type-news {
  .field-name-field-news-body {
    margin-top: 20px;
  }
  .field-name-field-news-date,
  .field-name-field-news-location {
    font-style: italic;
    color: #666;
  }
  .field-name-field-news-image {
    float: right;
    margin: 0 0 20px 20px;
  }
}

.date-display-range {
  display: inline;
}

// Localist Events
.overflow-list {
  background-color: #f1f1f1;
  height: 175px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 93%;
  padding: 10px;
  border: 1px solid #ccc;
  li {
    margin-bottom: 9px;
  }
}

.field-name-field-localist-description {
  margin: 30px 0;
}

.field-name-field-localist-date {
  font-style: italic;
}

.field-name-field-localist-location {
  font-weight: bold;
}

.field-name-field-localist-image {
  float: right;
  margin: 0 0 20px 20px;
}

.views-field-field-localist-url {
  i {
    font-style: normal!important;
  }
}

.view-hero-image {
  img {
    width: 100%;
  }
}
