.slider__text {
  position: relative;
  z-index: 1;
  bottom: 0;
  width: 100%;
  padding: 4%;
  text-align: center;
  background: #fff;
  @include breakpoint($all) {
    padding-bottom: 2%;
    position: absolute;
    background: transparent;
   // background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .22) 17%, rgba(0, 0, 0, .75) 57%, rgba(0, 0, 0, .75) 88%, rgba(0, 0, 0, .75) 100%);
  }
}

.views-field-field-home-slideshow-text {
     @include breakpoint($all) {
        background: linear-gradient(transparent 1%,transparent 49%,rgba(0,0,0,.7));
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
     }
}

.slider-text--banner {
  padding-bottom: 4%;
}

.slider__content {
  text-align: center;
  color: #000;
  @extend %font-main;
  font-size: 1.1875em;
  line-height: 1.26316em;
  @include breakpoint($small) {
    color: #fff;
  }
  @include breakpoint($medium) {
    color: #fff;
  }
  @include breakpoint($large) {
    color: #fff;
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
    font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif;
  }
  .front & {
    margin-bottom: 10px;
  }
}

.slider__caption {
  color: #000;
  font-size: .75em;
  font-style: italic;
  padding-top: 7px;
  opacity: .8;
  @include breakpoint($all) {color: #fff;}
}

.slider__heading {
  z-index: 1;
  margin-bottom: 20px;
  padding: 1px;
  text-align: center;
  color: #b49000;
  @extend %font-bold;
  font-size: 2.0625em;
  line-height: 1;
  @include breakpoint($small) {
    margin-bottom: 0;
    font-size: 2.0625em;
    line-height: 1.1;
    text-shadow: 0 1px 1px black;
    color: #edc649;
  }
  @include breakpoint($medium) {
    margin-bottom: 0;
    font-size: 3.125em;
    line-height: 1.1;
    text-shadow: 0 1px 1px black;
    color: #edc649;
  }
  @include breakpoint($large) {
    margin-bottom: 0;
    font-size: 3.125em;
    line-height: 1.1;
    text-shadow: 0 1px 1px black;
    color: #edc649;
  }
  span {
    padding: 0 10px 10px 10px;
  }
  strong {
    //color: #702323;
  }
}

.view.view-home-slideshow p {
  margin: 0;
}

.view-home-slideshow img {
  width: 100%;
}
