$base-font-size: 16px;
$base-line-height: 24px;
//@include establish-baseline;


%font5 {
    font-family: 'FontAwesome';
}

%font16, %font-main {
    font-family: 'ProximaNovaRegular', Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%font17, %font-bold {
    font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


%font18, %font-thin {
    font-family: 'ProximaNovaThThin', Arial, Helvetica, sans-serif;
    font-weight: 100;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%icon {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    padding-right: 10px;
}

%smoothing {
    -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}
