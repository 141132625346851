/********************** Universal **********************/

body,
html {
  font-family: 'ProximaNovaRegular', Arial, Helvetica, sans-serif;
  line-height: 1;
  height: 100%;
  background: #fff;
  @extend %font16;
}

html {
  -webkit-text-size-adjust: 100%;
}

body,
html {
  @extend %font16;
}

code {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
  font-weight: bold;
}

.wrapper {
  height: auto !important;
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
}

footer .hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

html,
body {
  height: 100%;
}

.wrapper img {
  max-width: 100%;
  height: auto;
  flex-shrink: 0;
}

span.ext {
  display: inline-block;
}

a {
  &:focus {
    outline: 1;
  }
}

.red {
  color: red;
}

.main {
  a {
    color: #369;
  }
  dl {
    margin-bottom: 10px;
  }
  dt {
    font-weight: bold;
  }
  dd {
    padding-left: 50px;
  }
  dd:first-child {
    padding-left: 0;
  }
  .small {
    font-size: 0.875em;
    line-height: 1.37143em;
  }
  strong,
  b {
    @extend %font17;
  }
  em,
  i {
    font-style: italic;

    &.fa {
      font-style: normal;
    }
  }
  u {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: underline;
  }
  blockquote {
    margin-right: 60px;
    margin-left: 60px;
    border-left: 8px solid #FFCD00;
    padding-left: 20px;
  }
  s,
  strike,
  del {
    text-decoration: line-through;
  }
  p {
    margin: 0 0 20px 0;
    @extend %smoothing;
    &.rteindent1 {
      margin: 0 0 20px 40px;
    }
    &.rteindent2 {
      margin: 0 0 20px 80px;
    }
  }

  ul,
  ol {
    margin: 0 0 20px 30px;
    ul,
    ol {
      margin: 0 0 0 20px;
    }
    &.lightbox {
      margin: 0;
    }
    &.views {
      margin: 20px 0 30px 0;
      li {
        display: inline-block;
        width: 100%;
        margin: 0 0 20px 0;
        list-style: none;
        &.border-bottom {
          padding: 10px 0;
          border-bottom: 1px solid #ccc;
        }
      }
      p {
        margin: 0;
      }
    }
  }

  li {
    margin: 0;
    list-style: disc;
  }

  ol>li {
    margin: 0 0 0 0;
    list-style: decimal;
  }

  ol.alpha-lower>li {
    list-style-type: lower-alpha;
  }

  ol.alpha-upper>li {
    list-style-type: upper-alpha;
  }

  ol.roman-lower>li {
    margin-left: 20px;
    list-style-type: lower-roman;
  }

  ol.roman-upper>li {
    margin-left: 20px;
    list-style-type: upper-roman;
  }

  hr {
    clear: both;
    height: 1px;
    color: #d4d4d4;
    border: 0 none;
    background-color: #d4d4d4;
    &.invisible {
      background-color: #fff;
    }
  }

  table {
    caption {
      @extend .element-invisible;
    }
    width: 100%;
    margin: 0 0 60px 0;
    padding: 0;
    p {
      margin: 0;
    }
    th,
    td {
      padding: 10px 20px 10px 0;
      text-align: center;
      width: 100%;
      float: left;
        @include breakpoint($all) {
            width: auto;
            float: none;
            text-align: left;
        }
    }
    th {
      border-width: 2px;
    }
    td {
      color: #333;
    }
    tr.views-row-last th,
    tr.views-row-last td {
      border-bottom: none;
    }
    tr {
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: none;
      }
      &.views-row-last {
        border-bottom: none;
      }
    }
    tr.even {
      border-bottom: 1px solid #ccc;
      background: transparent;

      &.views-row-last {
        border-bottom: none;
      }
    }
    tr.odd {
      border-bottom: 1px solid #ccc;
      background: transparent;

      &.views-row-last {
        border-bottom: none;
      }
    }
    tr.views-row-first {
      border-top: 3px solid #333;
    }
    thead th {
      @extend %font17;
    }
  }
}

.center {
  text-align: center;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

a {
  text-decoration: none;
}

.main p {
    line-height: 1.4;
    color: #333;
    margin-top: 0em;
    padding-top: 0em;
    padding-bottom: 0em;
    margin-bottom: 1.5em;
}

.not-front .main .views {
  margin: 0;
  li {
    display: inline-block;
    margin: 0 0 20px 0;
    list-style: none;
    &.services {
      float: left;
      width: 100%;
      min-height: 300px;
      @include breakpoint(medium) {
        width: 31%;
      }
      @include breakpoint(large) {
        width: 31%;
      }
      &:nth-child(3n+2) {
        @include breakpoint(medium) {
          margin: 0 15px;
        }
        @include breakpoint(large) {
          margin: 0 15px;
        }
      }
      p {
        font-size: .8em;
        font-style: italic;
      }
    }
  }
}

.more {
  text-transform: uppercase;
  font-size: 0.8125em;
  line-height: 1.84615em;
  a {
    color: #d75034;
  }
}

.not-front .main ul li {
  line-height: 1.4;
  margin-bottom: 5px;
  list-style: disc;
  color: #333;
}

.not-front .grid-8.main {
  padding-bottom: 100px;
}

.not-front .main ol li {
  line-height: 1.4;
  margin: 0 0 5px 0;
  list-style: decimal;
}

hr {
  clear: both;
  height: 1px;
  margin: 20px 0;
  color: #221c18;
  border: 0 none;
  background-color: #b4ccdf;
}


//responsive table
.view-people {
  table tr.views-row-first {
    @include breakpoint(small) {
      border: none;
    }
  }
  thead {
    @include breakpoint(small) {
      display: none;
    }
  }
  table tr {
    @include breakpoint(small) {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      padding: 0;
    }
  }
  table td {
    @include breakpoint(small) {
      float: left;
      clear: both;
      width: 100%;
      padding: 0;
      border: none;
    }
  }
}


.main h1 {
  font-size: 2.533em;
  line-height: 1em;
  margin-top: 0;
  margin-bottom: .5em;
  color: #000;
  @extend %font17;
  @extend %smoothing;
}

.main h2 {
  font-size: 1.6333em;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: .5em;
  @extend %font16;
  @extend %smoothing;
  &.view {
    font-size: 1.4em;
    margin: 0;
  }
  a {
    text-decoration: none;
  }
}

.main h3 {
  font-size: 1.4em;
  line-height: 1em;
  margin-top: 0;
  margin-bottom: .5em;
  @extend %font16;
  @extend %smoothing;
  &.view {
    font-size: 1.3em;
    margin: 0;
  }
  a {
    text-decoration: none;
  }
}

.no-svg .main h3.view {
  font-size: 1.1em;
}

.main h4 {
  font-size: 1.3em;
  line-height: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  @extend %font16;
  @extend %smoothing;
  &.view {
    margin: 0;
  }
  a {
    text-decoration: none;
  }
}

.main h5 {
  font-size: 1.2em;
  line-height: 1.125em;
  margin-top: 1.125em;
  margin-bottom: 1em;
  @extend %smoothing;
  a {
    text-decoration: none;
  }
}

.main h6 {
  font-size: 1.0667em;
  line-height: 1.2857em;
  margin-top: 1.2857em;
  margin-bottom: 0;
  @extend %smoothing;
}

.main table.vertical-align-top td {
  vertical-align: top;
}

.main table.two-column td {
  width: 50%;
}

.main table.three-column td {
  width: 33.3%;
}

.main table.four-column td {
  width: 25%;
}
