
// Profiles Page
.field-name-field-type {
    font-size: 1.9em;
    font-style: italic;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #ac882e;
}

.node-type-profile {
    h3.field-label {
        width: 100%;
        padding-bottom: 10px;
        float: none;
        margin-top: 30px;
    }
}

//polisci
.field-name-field-publications {
 // margin-top: 40px;
}


