
/********************** Flexslider **********************/

.flexslider {
    margin: 0px;
    background: #fff;
    border:none;
    position: relative;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow:none;
    -o-box-shadow: none;
    box-shadow:none;
    zoom: 1;
    @include breakpoint($all) {
        background: #000000;
    }

}


.flex-pauseplay a {
    width: 15px;
    height: 15px;
    bottom: 10px;
    color: #000;
    @include breakpoint($all) {
        color: #fff;
    }
}

.flex-pauseplay a:before {
    font-size: 14px;
}
