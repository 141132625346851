// Clas menu bar override
.clas-nav {
    border-bottom: none;
}

.clas a {
    margin: 15px 0 0 0;
    background-position: -9px -3px;
}

.clas {
    display: block;
    float: none;
    text-align: center;
    @include breakpoint($small) {
        float: left;
    }
    @include breakpoint($medium) {
        float: left;
    }
    @include breakpoint($large) {
        float: left;
    }
}

.clas a {
    margin-top: 24px;
    color: #fff;
    //background-position: -5px -1px;
    @include breakpoint($tiny) {
       // background-position: -10px -8px;
    }
    @include breakpoint($all) {
       // background-position: -10px -8px;
    }
    .lt-ie9 & {
      background-position: 0 0;
    }
}

ul.clas-nav {
  text-align: center;
  @include breakpoint($all) {
    text-align: left;
  }
}


header {
    background-color: #1e1d1d;
}


.logo {
    clear: left;
    padding: 0 0 10px 0;
    text-align: center;
    @include breakpoint($small) {
        padding: 0 0 20px 0;
        text-align: left;
    }
    @include breakpoint($medium) {
        padding: 0 0 12px 0;
        text-align: left;
    }
    @include breakpoint($large) {
        padding: 0 0 12px 0;
        text-align: left;
    }
    a {
        font-size: 1.4em;
        text-decoration: none;
        color: #fff;
        @extend %font16;
        @include breakpoint($small) {
            font-size: 1.8em;
        }
        @include breakpoint($medium) {
            font-size: 2.4375em;
        }
        @include breakpoint($large) {
            font-size: 2.4375em;
        }
    }
    &.small {
        a {
            font-size: 1.875em;
            line-height: 1.6em;
        }
    }
}

