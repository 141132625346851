html {
    -webkit-text-size-adjust: 100%;
    /* Prevent font scaling in landscape */
}

.front a strong {font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif;}

// Localist Events
.field-name-field-localist-description {
    margin: 30px 0;
}

.field-name-field-localist-date {
    font-style: italic;
}

.field-name-field-localist-location {
    font-weight: bold;
}

.field-name-field-localist-image {
    float: right;
    margin: 0 0 20px 20px;
}

.juicer-feed h1.referral {
  display: none!important;
}

.contact {
    display: inline-block;
    float: right;
    margin-left: 10px;
    padding: 7px;
    border-radius: 0 0 3px 3px;
    i {
        padding-right: 10px;
    }
    a {
        font-size: 1em;
        display: none;
        float: right;
        color: #999494;
        @extend %font16;
        @include breakpoint($small) {
            display: block;
        }
        @include breakpoint($medium) {
            display: block;
        }
        @include breakpoint($large) {
            display: block;
        }
    }
}



/********************** Honeypot **********************/

.url-textfield {
    display: none !important;
}
