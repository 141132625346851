.wrapper {
    height: auto !important;
    height: 100%;
    min-height: 100%;
    margin: 0 auto;
}

.container {
    padding: 0 2%;
    @include container;
    @include breakpoint($large) {
        max-width: 73em;
        padding: 0;
    }
}

.page-title {
  margin-bottom: 20px;
  padding: 25px 0 15px 0;
  h1 {
    font-size: 1.4em;
    @include breakpoint($all) {font-size: 2.333em;}
    line-height: 1;
    width: 100%;
    margin-right: 59px;
    padding-bottom: .3em;
    color: #1e1d1d;
    @extend %font-bold;
    @extend %smoothing;
  }
}

.main-container {
  padding-top: 20px;
}


#ui-wrapper {
  background: #000;
}

#uiowa-bar {
    max-width: 1090px;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint($large) {
        max-width: 1185px;
        padding: 0;
    }
}

#uiowa-bar #wordmark-link {
    @include breakpoint($small) {
        left: 16px;
    }
     @include breakpoint($large) {
        left: 11px;
    }
}

header,
footer {
    clear: both;
}

