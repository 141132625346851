
.calendar-calendar {
  td .inner div.day a {color: #38627f;}
  td.empty {color: #72723a;}
  table.mini {
/*    margin-bottom: 20px; */
    tbody {
      border: 1px solid #eee;
    }
  }
  th.days {
    padding: 8px !important;
    font-family: 'ProximaNovaRgBold',Arial,Helvetica,sans-serif;
    font-weight: normal;
    color: #606060;
    text-align: center !important;
  }
  tr td.today, tr.odd td.today, tr.even td.today {
    background-color: #fcfcee;
    border: 1px solid #ddd;
  }
  td {
    text-align: center !important;
    a {
      font-weight: normal;
    }
    span.date-display-single,
    span.date-display-start,
    span.date-display-end,
    span.date-display-separator {
      font-weight: normal;
    }
  }
  .year-view {
    div.month-view div.date-nav { background-color: transparent; }
  }
  .mini-day-on, .mini-day-off {
    margin: 6px 0 0;
    color: #666;
    a {
      color: #000;
      font-family: 'ProximaNovaRgBold',Arial,Helvetica,sans-serif;
      font-weight: normal;
      /* text-decoration: none; */
    }
  }
  .contents {
    font-size: 12px;
  }
}

/* Give responsiveness to the year view of the calendar */
.calendar-calendar .year-view > table > tbody > tr > td {
  @media screen and (max-width: 850px) {
    display: block;
    width: 100%;
  }
}

.calendar-calendar .week-view .full div.single-day .view-item,
.calendar-calendar .day-view .full div.single-day .view-item { padding: 0 2px; }

.calendar-calendar .week-view .full div.single-day div.weekview,
.calendar-calendar .day-view .full div.single-day div.dayview,
.calendar-calendar .month-view .full td.single-day div.monthview,
.calendar-calendar .month-view .full td.single-day div.dayview,
.calendar-calendar .week-view .full td.single-day div.weekview,
.calendar-calendar .day-view .full td.single-day div.dayview,
.calendar-calendar .month-view .full td.multi-day div.monthview,
.calendar-calendar .month-view .full td.multi-day div.dayview,
.calendar-calendar .week-view .full td.multi-day div.weekview,
.calendar-calendar .day-view .full td.multi-day div.dayview {
  background-color: #fcfcee;
  border: 1px solid #ddd;
  border-radius: 0 0 3px 3px;
  margin: 0 0 6px;
  padding: 0px;
  color: inherit !important;
  line-height: 1.3;
}
.calendar-calendar .day-view .full td.single-day div.dayview,
.calendar-calendar .day-view .full td.multi-day div.dayview {
  height: auto;
/*  width: 680px; Fixes width issue with events in the day view */
}
.calendar-calendar .month-view .full td.multi-day div.monthview,
.calendar-calendar .week-view .full td.multi-day div.weekview,
.calendar-calendar .day-view .full td.multi-day div.dayview {
  height: auto; /* Fixes height issue with multiday and allday events */
}
.calendar-calendar .month-view .full td.multi-day .inner .monthview .continuation,
.calendar-calendar .month-view .full td.multi-day .inner .monthview .continues,
.calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .continuation,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .continues,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff {
  background-color: transparent;
  border-color: #ddd;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  border-bottom-right-radius: 3px;
  margin: 0 0 6px;
  padding: 0px;
  color: inherit !important;
}
/* Removes the absolute positioning of multiday events */
.calendar-calendar .month-view .full td.multi-day .calendar.monthview .contents,
.calendar-calendar .week-view .full td.multi-day .calendar.weekview .contents {
  position: inherit;
  width: auto;
  left: 0;
}

/* Sets the padding around the contents inside an event */
.calendar-calendar .month-view .full .calendar.monthview .contents,
.calendar-calendar .month-view .full .calendar.dayview .contents,
.calendar-calendar .week-view .full .calendar.weekview .contents {
  padding: 3px;
}

/* Sets style of the legend striping in the month and day views */
.calendar-calendar .day-view td .stripe,
.calendar-calendar .month-view .full td .stripe,
.calendar-calendar .week-view .full td .stripe {
  border-radius: 0;
  height: 3px;
  width: 100%;
}

/* Sets font style of the dates in the month and day views */
.calendar-calendar .date-display-single {
  color: inherit !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.calendar-week-at-a-glance {
  .views-row { margin-bottom: 10px; }
  .views-row-last { margin-bottom: 20px; }
}

.view .date-nav-wrapper {
  .date-nav {
    margin: 0 !important;
  }
  .date-heading {
    position: absolute;
    line-height: 25px;
  }
  .date-prev, .date-next {
    position: relative;
    float: left;
    left: 0;
    background: none;
    border-radius: none;
    margin: 0;
    text-align: left;
    text-decoration: none;
    a {
      color: #666 !important;
      font-weight: inherit;
      &:hover {
        border-bottom: 1px solid #999;
      }
    }
  }
  .date-next {
    float: right;
    right: 0;
    text-align: right;
  }
}

.view-calendar {
  .view-header {
    margin: 0 !important;
    border-bottom: 0 !important;
    ul {
      margin: 10px 0 !important;
    }
  }
  .view-footer {
    margin: 5px 0 10px !important;
    p {
      margin: 0;
      font-size: 12px;
      text-align: center;
    }
  }
}

.view-calendar .view-header #legend,
.view-calendar .view-footer #legend {
  color: inherit !important;
  font-size: 12px !important;
}

  .calendar-calendar .month-view .full tr.single-day {
     @include breakpoint($small) {
       width: 180px;
     }
  }


@media screen and (max-width: 420px) {
  .date-nav div.date-heading h3 {
    //margin: 30px 0 0;
    padding: 0;
    text-align: center;
}
  .view .date-nav-wrapper .date-heading {
    text-align: left;
  }
  .calendar-calendar .month-view table.full {
    //position: relative;
  }
  .calendar-calendar .month-view .full tr td {
    width: 100%;
    float: left;
    text-align: left;
  }
  .calendar-calendar td {border:none;}
  .calendar-calendar tbody tr:hover td {
    background: none;
  }
  .calendar-calendar .month-view .full tr.single-day {
    border-top: none;
  }
  .calendar-calendar .month-view .full tr td.single-day:before {
   // content: attr(data-day-of-month) ",\0000a0" attr(headers);
   // font-weight: bold;
  }
  .calendar-calendar .month-view .full thead tr,
  .calendar-calendar .month-view .full tr th.days,
  .calendar-calendar .month-view .full tr td.date-box,
  .calendar-calendar .month-view .full tr td.no-entry,
  .calendar-calendar .month-view .full tr td.empty {
    display: none;
  }
  }
