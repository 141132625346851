/********************** Print **********************/

@media print {
    p.clas,
    .top-wrapper,
    .nav-wrapper,
    .content-wrapper .container-12 .grid-3,
    footer {
        display: none;
    }
    .nav-wrapper {
        border-top: none;
        border-bottom: none;
    }
    .content-wrapper .container-12 .grid-9 {
        width: 100%;
    }
}
