/*
.breadcrumbs_delimiter {
    font-size: 8px;
    line-height: 3.8;
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    vertical-align: bottom;
    color: #fff;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .1);
}

.region-crumbs a:first-child:after {
    font-size: 10px;
    line-height: 12px;
    margin-left: 14px;
    display: inline-block;
    width: 14px;
    height: 14px;
    content: '\f105';
    text-align: center;
    color: #000;
    @extend %font5;
}
*/

.crumbs-current-page {
  margin-left: 5px;
  color: #7c6221;
}

.breadcrumbs_item.current {
  color: #333;
}

.region-crumbs {
  background: #fff;
}


.breadcrumb {
  font-size: .9em;
  clear: both;
  margin: -10px 0 35px 0;
  list-style: none;
  border-radius: 0;
  @extend %font16;

  li {
    text-transform: none;
  }

  a {
    margin: 0 5px;
    color: #7c6221;
    text-transform: none;
  }
}

.breadcrumb a:first-child {
  margin-left: 0;
}
