.uiowa-event p.date-string {
  color: #6a6a6a;
  font-style: italic;
}

.uiowa-events-page .uiowa-event {
  width: 100%;
  clear: both;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: inline-block;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

div.block-uiowa-events div.uiowa-events-container div.uiowa-event p.uiowa-event-description {
  margin-top: 20px;
}

.block-uiowa-events h3 {
  a {
    color: #000;
  }
}

.block-uiowa-events .block-title {
  background: none;
  margin: 0 auto;
  padding: 5px 0;
  font-size: 1.375em;
  line-height: 1.2em;
  border-bottom: 1px solid #999;
  color: #333;
    @include breakpoint($medium) {
      max-width: 1040px;
    }

    @include breakpoint($large) {
      max-width: 1170px;
    }
  a {
    color: #333;
  }
}

.uiowa-event img {
  margin: 20px 0;
}

@media (min-width: 43em) {
  .uiowa-event img {
    float: right;
    margin: 0 0 20px 20px;
  }
}

.main-content .uiowa-event h3 {
  margin-bottom: .2em;
}

.main-content ul.pagination {
  margin: 20px 0;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0!important;
  border-radius: 4px;
}

.pagination>li {
  display: inline;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  border: 1px solid #333;
  margin-left: -1px;
}

.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>.active>a:focus,
.pagination>.active>span,
.pagination>.active>span:hover,
.pagination>.active>span:focus {
  z-index: 3;
  color: #fff;
  background-color: #333;
  border-color: #000;
  cursor: default;
}



.horizontal-list .uiowa-events-container,
.horizontal-list ul {
  @include breakpoint($all) {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}

.block-uiowa-events .uiowa-events-container,
.horizontal-list .view-content,
.horizontal-list .view-header  {
  margin: 0 auto;
  max-width: 68em;
  padding: 0 2%;
  position: relative;
}


.horizontal-list .view-header .content-box-link {
    top: 0;
}


.horizontal-list .uiowa-events-container,
.horizontal-list .view-content,
.horizontal-list .view-header {
  @include breakpoint($large) {
    max-width: 73em;
    margin: 0 auto;
    padding: 0;
  }
}


.horizontal-list .uiowa-event,
.horizontal-list li {
  @include breakpoint($all) {
    -webkit-flex: 1 0 180px;
    -ms-flex: 1 0 180px;
    flex: 1 0 180px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
  }
}

.horizontal-list {
  clear: both;
  //margin-top: 10px;
}


.horizontal-list .uiowa-event,
.horizontal-list li {
  margin: 1% 1%;
  //min-height: 100px;
  clear: both;
  //padding: 2%;
  width: 100%;
  vertical-align: top;
  position: relative;
}

.horizontal-list .uiowa-event,
.horizontal-list ul li {
  @include breakpoint($all) {
   // padding: 0 1%;
  }
  &:last-child {
    @include breakpoint($all) {
      //padding-left: 2%;
      padding-right: 0;
      margin-right: 0;
    }
  }
  &:first-child {
    @include breakpoint($all) {
      padding-left: 0;
      //padding-right: 2%;
      margin-left: 0;
    }
  }
}


// calendar format

.horizontal-list.events-list ul li {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 30px;
    padding-left: 120px;
    //background: #fff;
}

.front .date-content {
    position: relative;
    min-height: 120px;
    padding: 20px 14px 13px 24px;
}


.front .date {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 0;
  text-align: center;
  background: #FFCD00;
  line-height: 1;
  width: 100%;
}

.front .date-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  max-width: 120px;
  height: 100%;
  padding: 0;
  color: #000;
  border: none;
  //background: #FFCD00;
}

.front .date-content {
  position: relative;
  min-height: 120px;
  padding: 0 14px 13px 24px;
}

.m {
  font-size: .8em;
  display: block;
  padding: 5px 0;
  letter-spacing: .08em;
  text-transform: uppercase;
  @extend %font-thin;
}

.y {
  font-size: .8em;
  margin-top: 3px;
  padding-top: 3px;
  border-top: 1px solid #000;
}

.d {
  font-size: 3em;
  display: block;
  margin-bottom: 6px;
  @extend %font-bold;
}
