.sidebar .block-menu {
  margin-bottom: 30px;
}

.sidebar .menu {
  clear: both;
  @extend %font16;

  >li {
    position: relative;
    -webkit-transition: height .25s;
    -moz-transition: height .25s;
    -o-transition: height .25s;
    transition: height .25s;
    border-bottom: 1px solid #ccc;
    color: #333;
    cursor: pointer;
        margin-top: 0em;
        padding-top: 0em;
        padding-bottom: 0.45em;
        margin-bottom: 0.45em;
        font-size: 1.0625em;
        line-height: 1.3;

    &.has-children>a:after {
      font-size: .9em;
      padding-left: 5px;
      content: '\f078';
      color: #999;
      @extend %icon;
    }

    &.has-children>.nolink:after {
      font-size: .9em;
      padding-left: 5px;
      content: '\f078';
      color: #999;
      @extend %icon;
    }

    &.last {
      border-bottom: none;
    }
  }

  >li>ul>li>ul>li {
    margin-left: 20px;

    a {
      color: #333;
    }
  }

  >li>ul>li {
    border-bottom: none;
       font-size: 0.875em;
       line-height: 1.14286em;
       margin-top: 0.6em;
       padding-top: 0em;
       padding-bottom: 0.15em;
       margin-bottom: 0.15em;

    &.has-children>a:after {
      content: '\f0d7';
      font-size: 0.875em;
      line-height: 1.71429em;
    }

    /*&.expanded.active-trail.has-children > a:after  {
            content: "\f0d8";
            @include adjust-font-size-to(14px);
        }*/
    a {
      color: #6a6a6a;

      &.active {
        color: #000;
      }
    }
  }

  a {
    color: #333;
    cursor: pointer;

    &.active {
      color: #000;
      @extend %font17;
    }
  }
}


body:before {
  display: none;
  content: 'smartphone';
}

@media (min-width: 0) and (max-width: 775px) {
  body:before {
    content: 'smartphone_menu';
  }
}


.menu-button {
  font-size: 1.4em;
  line-height: 1;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  cursor: pointer;
  text-align: left;
  color: #111;
  border: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fafafa;

  @include breakpoint($small) {
    display: none;
  }

  @include breakpoint($medium) {
    display: none;
  }

  @include breakpoint($large) {
    display: none;
  }

  i {
    float: right;
  }
}

.sidebar .block-menu-sidebar .menu,
.region-fullscreen-slideshow .block-menu-sidebar .menu,
.region-fullscreen-slideshow .bef-select-as-links {
  &.disabled {
    display: none;

    @include breakpoint($small) {
      display: block;
    }

    @include breakpoint($medium) {
      display: block;
    }

    @include breakpoint($large) {
      display: block;
    }
  }

  &.active {
    display: block;

    @include breakpoint($small) {
      display: block;
    }

    @include breakpoint($medium) {
      display: block;
    }

    @include breakpoint($large) {
      display: block;
    }
  }

  &.disabled.active {
    display: block;

    @include breakpoint($small) {
      display: block;
    }

    @include breakpoint($medium) {
      display: block;
    }

    @include breakpoint($large) {
      display: block;
    }
  }
}

.toggle-menu {
  padding: 0 10px;
  cursor: pointer;
  color: #fff;

  @include breakpoint($small) {
    display: none;
  }

  @include breakpoint($medium) {
    display: none;
  }

  @include breakpoint($large) {
    display: none;
  }

  border-radius: 4px;
  @extend %smoothing;

  &.main {
    background: #232121;
  }

  a {
    color: #fff;
  }

  &.secondary:before {
    color: #333;
  }

  &.secondary {
    color: #333;
    border: 1px solid #ccc;
  }

  &:before {
    content: '\f0c9';
    color: #999;
    @extend %icon;
    font-size: 1.313em;
  }
}
