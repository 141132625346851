.main-content img {
    max-width: 100%;
    max-height: 100%;
}

.left {
  @include breakpoint($all) {
    float: left;
    margin: 5px 15px 25px 0;
  }
}

.right {
    @include breakpoint($small) {
        float: right;
        margin: 0 15px 25px 25px;
    }
    @include breakpoint($medium) {
        float: right;
        margin: 0 15px 25px 25px;
    }
    @include breakpoint($large) {
        float: right;
        margin: 0 15px 25px 25px;
    }
}


.border-radius {
    img {
        border-radius: 3px;
    }
}

.border {
    border: 6px solid #fff;
}

.large {
    font-size: 1.5em;
}
