footer hr {
    background-color: rgba(255, 255, 255, .35);
}

footer .btn {
    display: block;
     @include breakpoint($small) {
         display: inline-block;
     }
}

// alert box for accessibility statement and sitewide alerts that appear under the navbar.

.main p.alert {
  padding: 20px;
}

.alert {
    background: #e5e5e5;
    box-shadow: 0 0 8px rgba(0, 0, 0, .15) inset;
    .element-invisible {
        color: #000;
    }
    &.alert--top {
        padding: 10px 0;
        background: #ffce00;
    }
    i {
        color: #62551a;
    }
    p {
        line-height: 1.5;
        color: #333;
        @extend %font16;
        strong {
            color: #333;
            @extend %font17;
        }
        a {
            padding-bottom: 3px;
            color: #9b370b;
            border-bottom: 1px solid #9b370b;
        }
    }
}

/********************** footer **********************/

.bottom-wrapper {
    letter-spacing: .03em;
    text-transform: uppercase;
    color: #000;
    background: $iowa-gold;
    box-shadow: 0 0 8px rgba(0, 0, 0, .15) inset;
    @extend %font16;
    p {
        color: #000;
    }
}

.bottom-nav {
    @include span(12);
    @include breakpoint($small) {
        @include span(8);
    }
    @include breakpoint($medium) {
        @include span(8);
    }
    @include breakpoint($large) {
        @include span(8);
    }
    a {
        float: left;
        padding: 0 15px 0 0;
        text-align: left;
        color: #000;
        font-size: 0.9375em;
        line-height: 1.28em;
    }
}

.copywrite {
    @include span(12);
    @include breakpoint($small) {
        @include span(4 last);
    }
    @include breakpoint($medium) {
        @include span(4 last);
    }
    @include breakpoint($large) {
        @include span(4 last);
    }
    p {
        text-align: left;
        @include breakpoint($small) {
            text-align: right;
        }
        @include breakpoint($medium) {
            text-align: right;
        }
        @include breakpoint($large) {
            text-align: right;
        }
            font-size: 0.9375em;
            line-height: 1.28em;
    }
}

.card {
    width: 100%;
    @include breakpoint($medium) {
        @include span(5);
    }
    @include breakpoint($large) {
        @include span(5);
    }
    a {
        padding-right: 10px;
        &.phone {
            padding-right: 0;
        }
    }
}

.social-media {
    width: 100%;
    @include breakpoint($medium) {
        @include span(5 last);
    }
    @include breakpoint($large) {
        @include span(5 last);
    }
    .action a {
        float: right;
        margin: 0;
    }
    h2 {
        margin: 20px 0;
        text-align: center;
        letter-spacing: .03em;
        text-transform: uppercase;
        @extend %font16;
            font-size: 1.125em;
            line-height: 1.06667em;
        color: $iowa-gold;
        @include breakpoint($small) {
            text-align: left;
        }
        @include breakpoint($medium) {
            margin: 10px 0;
            text-align: right;
        }
        @include breakpoint($large) {
            margin: 10px 0;
            text-align: right;
        }
    }
}

.donate {
    display: inline-block;
    width: 100%;
    margin: 20px 0;
    padding: 20px 0 0 0;
    border-top: 1px solid #6d6c6c;
    .donate--notice {
        font-size: .9em;
        width: 100%;
        @include breakpoint($small) {
            width: 70%;
        }
        @include breakpoint($medium) {
            width: 70%;
        }
        @include breakpoint($large) {
            width: 70%;
        }
    }
    .donate--button {
        float: right;
        width: 100%;
        margin-bottom: 15px;
        padding: 10px;
        text-align: center;
        color: #000;
        border-radius: 3px;
        background: $iowa-gold;
        @include breakpoint($small) {
            width: 25%;
        }
        @include breakpoint($medium) {
            width: 25%;
        }
        @include breakpoint($large) {
            width: 25%;
        }
    }
}

footer {
    position: relative;
    min-height: 220px;
    background: #1e1d1d;
    background-color: #1e1d1d;
    .container {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    hr {
        margin: 15px 0;
        background-color: #50504f;
    }
    i {
        padding-right: 15px;
        color: $iowa-gold;
    }
    p {
        font-size: 1em;
        line-height: 1.3em;
        margin-bottom: 10px;
        text-align: center;
        color: #b9b5b5;
        @extend %font16;
        @extend %smoothing;
        @include breakpoint($small) {
            text-align: left;
        }
        @include breakpoint($medium) {
            text-align: left;
        }
        @include breakpoint($large) {
            text-align: left;
        }
        &.icon {
          line-height: 2;
        }
        &.icons {
            line-height: 2;
            clear: both;
            @include breakpoint($medium) {
                text-align: right;
            }
            @include breakpoint($large) {
                text-align: right;
            }
            a {
                margin-right: 20px;
                @include breakpoint($medium) {
                    margin-right: 0;
                    margin-left: 20px;
                }
                @include breakpoint($large) {
                    margin-right: 0;
                    margin-left: 20px;
                }
            }
        }
        &.footer-logo {
            display: none;
            opacity: .8;
            @include breakpoint($small) {
                display: block;
            }
            @include breakpoint($medium) {
                display: block;
            }
            @include breakpoint($large) {
                display: block;
            }
            a {
                font: 0/0 a;
                display: block;
                width: 100%;
                height: 24px;
                margin-top: 5px;
                color: transparent;
                border: 0;
                background: url('../images/clas2.png') no-repeat center;
                background-size: 100%;
                text-shadow: none;
            }
        }
    }
    a {
        color: #fff;
        @extend %font16;
    }
    strong {
        color: #fff;
        @extend %font17;
    }
}
