/********************** Lightbox **********************/


a.lightbox-processed, a.colorbox {
  display: inline;
  float: left;
  margin: 10px 10px 10px 0;
}

.lightbox {
  ul {
    list-style:none;
    li {
      list-style: none;
      float: left;
    }
  }
}





#colorbox {
    background: none!important;
    border-radius: 0!important;
    box-shadow: none!important;
  }


#cboxTitle {
    color: #fff!important;
    padding: 4px 140px 0 15px!important;
  }


#cboxCurrent {
    position: absolute;
    bottom: -26px;
    right: 80px;
    color: #fff;
    border-left: 1px solid #fff;
    padding: 0 0 0 15px;
  }

  #cboxPrevious {
    background: url("../images/controls.png") no-repeat 0 -48px;
}

#cboxNext {
    background: url("../images/controls.png") no-repeat 0 -29px;
}
