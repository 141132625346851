.top-wrapper {
    //min-height: 55px;
    border-bottom: 1px solid #000;
    background-color: #1c1915;
}

.top-page-menu {
    .nav li {text-transform: none;}
    clear: both;
    padding: 6px 0 14px;
    text-align: center;
    @include breakpoint($small) {
        text-align: left;
    }
    @include breakpoint($medium) {
        float: right;
        clear: none;
        padding: 17px 0 0;
    }
    @include breakpoint($large) {
        float: right;
        clear: none;
        padding: 17px 0 0;
    }
    li {
        font-size: .8em;
        display: inline;
        padding: 0 10px 0 0;
        @extend %font16;
        @include breakpoint($medium) {padding: 0 10px;border-right: 1px solid #999;}
        @include breakpoint($large) {padding: 0 10px;border-right: 1px solid #999;}
        &.last {
            border-right: none;
        }
        a {
            color: #ccc;
        }
    }
}


#uiowa-bar svg {
    width: 210px;
}


#uiowa-search .links li {
  @extend %font16;
  @include breakpoint($all) {
    padding: 0 10px;
    border-right: 1px solid #999;
  }
  &.last {
    border-right: none;
  }
}

#uiowa-bar #uiowa-search .links a {
    font-size: 1.1em;
    margin-right: 0;
}

.uiowa {
    margin: 0;
    @include breakpoint($all) {float: left;margin: 7px 0 0;}
   /* @include span(12);
    @include breakpoint($small) {
        margin: 7px 0 0 0;
        @include span(5);
    }
    @include breakpoint($medium) {
        margin: 7px 0 0 0;
        @include span(3);
    }
    @include breakpoint($large) {
        margin: 7px 0 0 0;
        @include span(3);
    }*/
    a {
        display: block;
        overflow: hidden;
        height: 35px;
        width: 222px;
        white-space: nowrap;
        text-indent: 100%;
        color: $iowa-gold;
        background: url('../images/uiowa-gold.svg') no-repeat center center;
        margin: 0 auto;
        @include breakpoint($small) {
            background: url('../images/uiowa-gold.svg') no-repeat;
            margin: 0;
        }
        @include breakpoint($medium) {
            background: url('../images/uiowa-gold.svg') no-repeat -3px -2px;
            background-size: 100%;
            margin: 0;
        }
        @include breakpoint($large) {
            background: url('../images/uiowa-gold.svg') no-repeat -3px -2px;
            background-size: 100%;
            margin: 0;
        }
        .lt-ie8 &,
        .lt-ie9 & {
            background: url('../images/uiowa-gold.png') no-repeat -3px -2px;
        }
    }
}
