//  Quick Facts
.feature-block--quickfacts {
  width: 100%;
  padding: 40px 0;
  background-size: cover;
  color: #fff;
  text-align: center;
  font-size: 0.7em;
  .container {
    text-shadow: 0 1px 1px #000;
    filter: dropshadow(color=#000000, offx=0, offy=1);
  }
  .charwrapper {
    display: block;
    float: left;
    margin: 10px 0;
    width: 100%;
    vertical-align: top;
  }
  .hugechars {
    font-family: 'ProximaNovaRgBold',Arial,Helvetica,sans-serif;
    font-size: 5.2em;
    line-height: 1;
  }
  p {
    font-size: 1.3em;
    line-height: 1.3;
    margin: 40px 0;
    display: inline-block;
  }
  strong {
    font-weight: bold;
  }
  @include breakpoint($small) {
    .charwrapper {
      margin: 25px 0;
      width: 33%;
      border-right: 1px dashed #fff;
      &.last { border-right: none; }
    }
  }
  @include breakpoint($medium) {
    font-size: 0.8em;
    .charwrapper {
      margin: 25px 0;
      width: 33%;
      border-right: 1px dashed #fff;
      &.last { border-right: none; }
    }
  }
  @include breakpoint($large) {
    font-size: 1em;
    .charwrapper {
      margin: 25px 0;
      width: 33%;
      border-right: 1px dashed #fff;
      &.last { border-right: none; }
    }
  }
}

