
.collapsible .field-name-field-careers-block-link a,
.collapsible .field-name-field-careers-sub-block-link a {
    background: #fccb00;
    padding: 10px 20px;
    color: #000;
    border-radius: 2px;
    margin-bottom: 10px;
    display: inline-block;
    text-transform: uppercase;
    font-size: .7em;
    font-family: ProximaNovaRgBold,Arial,Helvetica,sans-serif;
}


.field-name-field-careers-image {
    width: 65px;
    margin: 0 auto;
}

.field-name-field-careers-sub-block-content ul,
.field-name-field-careers-sub-block-content ol {
    clear: both;
}

.field-name-field-careers-image {
  @include breakpoint($all) {
    float: right;
    margin-top: -10px;
}
}

.field-name-field-careers-sub-block-heading {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 1.3em;
  text-transform: uppercase;
}

.collapsible {
  .field-name-field-careers-sub-block-image {
    float: left;
    margin: 0 20px 20px 0;
  }
  .view-content {
  }
  .views-row {
    margin: 0 0 20px 0;
    border: 1px solid #ccc;
    width: 100%;
    padding: 20px;
    position: relative;
    background: rgba(241, 241, 241, 0.31);
  }
  .paragraphs-items-field-careers-block-full  {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  .field-name-field-careers-block {
    -webkit-flex: 1 0 280px;
    -ms-flex: 1 0 280px;
    flex: 1 0 280px;
    margin: 0 10px 0 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
  }
 }
}

/*
.accordion {
    div {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    h3 {
        padding: 10px 30px 10px 10px;
        cursor: pointer;
        border-radius: 3px;
        background: #efebda;
        font-size: 1em;
        text-transform: uppercase;
        position: relative;
        a {
            color: #fff;
        }
        &:after {
            font-size: 1em;
            font-weight: normal;
            font-style: normal;
            display: inline-block;
            float: right;
            position: absolute;
            top: 10px;
            right: 0;
            content: '\f055';
            text-decoration: inherit;
            color: #000;
            @extend %icon;
        }
        &.ui-state-active:after,
        &.active:after {
            content: '\f056';
            color: #000;
        }
        &:hover {
            color: #666;
        }
    }
}
*/

.main .collapsible button {
    padding: 5px 0 5px 10px;
    letter-spacing: .08em;
    width: 100%;
    text-align: center;
    @include breakpoint($all) {
        width: 85%;
        text-align: left;
    }
    &[aria-expanded='true']:after {
        padding-left: 10px;
        content: "\f056";
        @extend %icon;
    }
    &[aria-expanded='true']:after,
    &[aria-expanded='true']:before {
        font-size: .9em;
        color: #999;
  }
    &[aria-expanded='false']:after {
        padding-left: 10px;
        content: "\f055";
        @extend %icon;
    }
    .field {
      display: inline;
    }
}


.collapsible div[aria-hidden=true] {
  border: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
}

/* Reset `button` and button-style `input` default styles */

.main .collapsible button:hover {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.main .collapsible input[type="submit"],
.main .collapsible input[type="reset"],
.main .collapsible input[type="button"],
.main .collapsible button {
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    box-shadow: none;
    text-shadow: none;
    -webkit-box-shadow: none;
    -webkit-appearance: button; /* for input */
    -webkit-user-select: none; /* for button */
       -moz-user-select: none;
        -ms-user-select: none;
}
.main .collapsible input::-moz-focus-inner,
.main .collapsible button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* Make `a` like a button */
.main .collapsible [role="button"] {
    color: inherit;
    cursor: default;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: pre;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
}
