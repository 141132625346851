/********************** Responsive Iframes **********************/

.flexible-container {
    position: relative;
    clear: both;
    overflow: hidden;
    height: 0;
    padding-top: 0;
    padding-bottom: 56.25%;
    &.facebook {
        padding-bottom: 2%;
    }
    &.flexible-footer {
        float: right;
        width: 40%;
        padding-bottom: 0;
    }
}

.flexible-container {
    position: relative;
    clear: both;
    overflow: hidden;
    height: 0;
    padding-top: 0;
    padding-bottom: 56.25%;
}

.flexible-container iframe,
.flexible-container object,
.flexible-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



.Flexible-container iframe,
.Flexible-container object,
.Flexible-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
