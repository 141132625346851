

// moved to editor.css remove at some point

.caption {
	width: 250px;
	font-style: italic;
	&.right {
		clear: right;
    float: right;
    margin: 0 0 10px 15px;
	}
	&.left {
		clear: left;
    float: left;
    margin: 0 5px 10px 0;
	}
	&.medium {
		width: 350px;
	}
	&.long {
		width: 450px;
	}
	&.full {
		width: 100%;
	}
}


.p-caption-right {
    clear: right;
    float: right;
	font-style: italic;
    margin: 0 0 10px 15px;
    padding: 0 0 0 40px;
    width: 255px;
}

.p-caption-left {
    clear: left;
    float: left;
	font-style: italic;
    margin: 0 5px 10px 0;
    padding: 0 20px 0 0;
    width: 255px;
}

.image-right {
	float: right;
    margin: 10px 0 10px 15px;
}

.image-left {
	float: left;
    margin: 10px 15px 10px 0;
}

.image-center {
	margin: 0 auto;
	display: block;
	text-align: center;
}

.image-captioned {
	font-size: 0.9375em;
	line-height: 1.28em;
	color: #333;
    em {
    	font-style: italic;
    }
    strong {
    	font-weight: bold;
    }
}
