
.field .field-label,
.field-label {
    @extend %font17;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
    float: left;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
    float: left;
}

.field-label {
    font-size: 1.1em;
   // line-height: 1.1;
    float: left;
    padding-right: 10px;
}

.field {
    font-size: 1.1em;
    //line-height: 1.2;
    margin-bottom: 8px;
    .front & {
        margin-bottom: 0;
    }
}
//polisci
.main .field p {
  //margin-bottom: 0;
  //line-height: 1;
}

// Profile Professional title
/*
.field.field-name-field-title:nth-child(3) {
    margin-bottom: 30px;
}
.node-type-profile .field.field-name-field-profile-title:nth-child(3) {
    margin-bottom: 30px;
}
*/
