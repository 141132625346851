/********************** Nav  **********************/

.block-menu-sidebar h2,
.block-superfish h2 {
  @extend .element-invisible;
}

.block-menu h2 {
  @extend %font-bold;
  font-size: 1.2em;
  margin-bottom: 15px;
}

// Nav
header nav {
  display: inline-block;
  clear: both;
  width: 100%;
  margin-top: 10px;

  @include breakpoint($small) {
    margin-top: 0;
  }

  @include breakpoint($medium) {
    margin-top: 0;
  }

  @include breakpoint($large) {
    margin-top: 0;
  }
}

#main-nav .container {
  box-shadow: inset rgba(255, 255, 255, .1) 0 1px 0, inset rgba(0, 0, 0, .3) 0 -1px 0;
  border-top: 1px solid rgba(0, 0, 0, .3);
  padding-top: 2px;
}

.menu.sf-horizontal {
  margin: 0;

  li ul {
    text-align: left;
    border: none;
  }
}

.menu.sf-horizontal li {
  float: left;
  list-style-type: none;
  cursor: pointer;
}

.menu.sf-horizontal li a:hover {
  @extend %gold;
}

.menu.sf-horizontal {
  padding: 8px 0;

  li a.active {
    @extend %gold;
  }

  ul li a.active {
    color: #ffe57c;
  }
}

.menu.sf-horizontal li>a {
  display: block;
  padding: 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  @extend %font16;

  @include breakpoint($medium) {
    padding: 6px 17px;
    border-right: 1px solid rgb(0, 0, 0);
    border-left: 1px solid rgba(255, 255, 255, .2);
    text-shadow: 1px 1px rgba(0, 0, 0, .3);
  }

  @include breakpoint($nav) {
    padding: 6px 10px;
  }

  @include breakpoint($large) {
    padding: 6px 17px;
    border-right: 1px solid rgb(0, 0, 0);
    border-left: 1px solid rgba(255, 255, 255, .2);
    text-shadow: 1px 1px rgba(0, 0, 0, .3);
  }
}

.menu.sf-horizontal li.last>a {
  border-right: none;

  @include breakpoint($medium) {
    padding: 6px 0 6px 17px;
  }

  @include breakpoint($nav) {
    padding: 6px 0 6px 10px;
  }

  @include breakpoint($large) {
    padding: 6px 0 6px 17px;
  }
}

.menu.sf-horizontal li.first>a {
  border-left: none;

  @include breakpoint($medium) {
    padding: 6px 17px 6px 0;
  }

  @include breakpoint($nav) {
    padding: 6px 10px 6px 0;
  }

  @include breakpoint($large) {
    padding: 6px 17px 6px 0;
  }
}

.menu.sf-horizontal ul li>a {
  border-right: none;
  text-transform: none;
}

.menu.sf-horizontal ul li.first>a {
  @include breakpoint($medium) {
    padding: 6px 17px;
  }

  @include breakpoint($nav) {
    padding: 6px 17px;
  }

  @include breakpoint($large) {
    padding: 6px 17px;
  }
}

.menu.sf-horizontal li ul li a {
  font-size: .9em;
  text-align: left;
}


.menu.sf-horizontal {
  position: relative;
  display: inline-block;

  ul {
    // top: 2em!important;
  }
}

.sf-menu li {
  float: none;
}

.sf-depth-2.nolink {
  display: none !important;
}

/********************** Responsive Menus  **********************/

.sf-accordion-toggle a {
  font-size: 1.1em;
  display: block;
  padding: 10px;
  color: #fff;
  @extend %font16;
  -webkit-font-smoothing: antialiased;

  &:before {
    margin-right: .3em;
    content: '\f0c9';
    color: #ffce00;
    @extend %icon;
  }
}

.sf-accordion li {
  border-right: none;
  border-left: none;

  &:not(:last-child) {
    border-bottom: 1px solid #666;
  }
}

.sf-accordion li a {
  display: block;
  margin: 0;
  padding: 10px;
  text-align: left;
  text-decoration: none;
  color: #fff;
  border-right: none;
  border-left: none;
  @extend %font16;
}

.sf-accordion>li.menuparent>a {
  &:after {
    margin-left: .3em;
    content: '\f078';
    color: #666;
    @extend %icon;
  }
}

.sf-accordion>li.menuparent ul>li>a {
  color: #fff;
  @extend %font16;

  &:after {
    content: '';
  }
}

.sf-accordion ul {
  background: #666;
}





// secondary content spans the final 3 (omega) of 9 columns.
.secondary {
  position: relative;
  padding: 0;
  @include span(12);

  @include breakpoint($small) {
    padding: 1.4em 1.4em 1.4em;
    @include span(4 last);
  }

  @include breakpoint($medium) {
    padding: .4em 0 1.4em 1.4em;
    @include span(4 last);
  }

  @include breakpoint($large) {
    padding: .4em 0 1.4em 1.4em;
    @include span(4 last);
  }

  .no-sidebars & {
    display: none;
  }
}

// Dropdown Menu


#superfish-1 li:hover ul {
  /*  top: 28px;*/
}

@media (min-width: 980px) {

  #superfish-1 li:hover>ul,
  #superfish-1 li.sfHover>ul {
    top: 28px;
  }
}


#superfish-1 ul:after {
  bottom: 100%;
  left: 8%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 205, 0, 0);
  border-bottom-color: #FFCD00;
  border-width: 10px;
  margin-left: -10px;
}

#superfish-1 ul {
  background: #fff;
  border-top: 4px solid #FFCD00;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  position: absolute;
  padding: 20px 10px;

  width: 300px !important;
  z-index: 1000;
  -webkit-transition: none !important;
  transition: none !important;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
}

#superfish-1 ul li a {
  color: #333;
  text-shadow: none;
}
