
// main content spans 6 of those 9 columns.
.main {
    .not-front & {
        padding-bottom: 30px;
        @include span(12);
        @include breakpoint($small) {
            @include span(8);
            margin-right: 0;
        }
        @include breakpoint($medium) {
            @include span(8);
            margin-right: 0;
        }
        @include breakpoint($large) {
            @include span(8);
            margin-right: 0;
        }
    }
    .not-front.no-sidebars & {
        @include span(12);
        @include breakpoint($small) {
            @include span(12);
        }
        @include breakpoint($medium) {
            @include span(12);
        }
        @include breakpoint($large) {
            @include span(12);
        }
    }
    article {
        padding: 1.4em;
        .not-front & {
            min-height: 442px;
            padding: 0;
        }
    }
}


.column {
    @include span(12);
    @include breakpoint($small) {
        @include span(4);
    }
    @include breakpoint($medium) {
        margin: 10px 0;
        @include span(4);
    }
    @include breakpoint($large) {
        margin: 10px 0;
        @include span(4);
    }
}
