.pricing-table {
  width: 100%;
  margin: 0px auto 15px 0;
  text-align: center;
  padding-right: 0;
  @include breakpoint($all) {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
  }
}

.pricing-block {
  display: flex;
  width: 100%;
  flex-flow: column;
  overflow: hidden;
  margin: 2% 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #f1f1f1;
  @include breakpoint($small) {
    //width: 19%;
    margin: 0 1% 0 0;
  }
  @include breakpoint($medium) {
    //width: 19%;
    margin: 0 1% 0 0;
  }
  @include breakpoint($large) {
    //width: 19%;
    margin: 0 1% 0 0;
  }
}

.pricing-title {
  color: #1e1d1d;
  background: #edc649;
  display: block;
  text-transform: capitalize;
  padding: 20px 10px;
  position: relative;
  font-weight: bold;
}

.pricing-title:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #f1f1f1;
  border-top-color: #edc649;
  border-width: 10px;
  margin-left: -10px;
}

.pricing-content {
  list-style-type: none;
  text-align: left;
  color: #000;
  text-align: center;
  padding: 20px 10px 10px 10px;
  font-size: 0.9em;

  @include breakpoint($small) {
    //min-height: 285px;
  }

  @include breakpoint($medium) {
    //min-height: 285px;
  }

  @include breakpoint($large) {
    //min-height: 285px;
  }
}

.pricing-footer {
  background: #626d70;
  padding: 9px;
  font-size: .8em;
  align-items: center;
  margin-top: auto;
  a {
    color: #fff;
    text-transform: uppercase;
  }
}
