// photo feature
.figure-spotlight {
    position: relative;
    text-align: center;
    padding: 0 90px;
    @include breakpoint($small) {padding: 0;}
    @include breakpoint($medium) {padding: 30px;}
    @include breakpoint($large) {padding: 30px;}
}

.figure-spotlight__img  {
    border-radius: 50%;
    box-shadow: 1px 1px 0 rgba(255,255,255,0.8), 2px 2px 2px rgba(0,0,0,0.35);
}

.figure-spotlight__primary {
    bottom: 38px;
    left: 15%;
    color: #000;
    background: #edc649;
    letter-spacing: .05em;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0 12px;
    text-transform: uppercase;
    text-shadow: 1px 1px 0 rgba(255,255,255,0.8), 2px 2px 2px rgba(0,0,0,0.15);
    @include breakpoint($medium) {
    position: absolute;
    width: 70%;
  }
    @include breakpoint($large) {
    position: absolute;
    width: 70%;
  }
}

.figure-spotlight__secondary {
    bottom: 5px;
    left: 0;
    background: #fff;
    width: 100%;
    font-style: italic;
    font-size: 22px;
    padding: 6px 0;
    color: #0b0903;
    display: block;
    @include breakpoint($medium) {
    position: absolute;
  }
    @include breakpoint($large) {
    position: absolute;
  }
}

.figure-spotlight__tertiary  {
    bottom: -20px;
    width: 100%;
    background: #fff;
    left: 0;
    color: #000;
    padding-bottom: 10px;
    @include breakpoint($medium) {
    position: absolute;
  }
    @include breakpoint($large) {
    position: absolute;
  }
    i {
      padding-right: 5px;
      color: #ccc;
    }
}

// Juicer
.juicer {
    @extend %font16;
    font-size: 2em;
    margin: 40px 0 10px;
    display: inline-block;
    @include breakpoint($medium) { margin: 40px 0 30px;}
    @include breakpoint($large) { margin: 40px 0 30px;}
}

.juicer-icons {
    text-align: center;
    @include breakpoint($medium) {    margin-top: 50px;float: right;  text-align: right;}
    @include breakpoint($large) {    margin-top: 50px;float: right;  text-align: right;}
    a {
        margin-left: 20px;
        color: #666;
         i {
        padding-right: 10px;
        &.fa-twitter-square {
            color: #3b94d9;
        }
        &.fa-facebook-official {
            color: #365899;
        }
    }
    }
}
